* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.light {
  --background-color: #FFFFFF;
  --text-color: #1E2324;
  --button-text-color: #FFFFFF;
  --button-color: #24BB74;
  --text-color-secondary: #CACACA;
  --text-color-hint: #ABB0BC;
}

:root {
  --background-color: #1E2324 !important;
  --text-color: #FFFFFF !important;
  --button-text-color: #FFFFFF !important;
  --button-color: #24BB74 !important;
  --text-color-secondary: #ABB0BC !important;
  --text-color-hint: #A6A6A6 !important;
}

body {
  background-color: var(--background-color);
}

.app {
  max-width: 600px;
  min-height: 100vh;
  position: relative;
  padding: 36px;
  margin: auto;
  background-color: var(--background-color);
}


.sum__text {
  color: var(--text-color-secondary);
  font-size: 20px;
}

.sum__value {
  color: var(--text-color);
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
}

.row__data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.row__data__key {
  color: var(--text-color-secondary);
  font-size: 20px;
}
.row__data__value {
  color: var(--text-color);
  font-size: 20px;
}

.choose__payment {
  color: var(--text-color);
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: bold;
}

.select {
  width: 100%;
  border: 1px solid var(--text-color-hint);
  padding: 10px;
  color: var(--text-color);
  border-radius: 8px;
  font-size: 20px;
  margin-bottom: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('./../public/arrow-bottom.svg') no-repeat right 10px center;
  background-size: 16px;
  background-color: var(--background-color);
}

.select::-ms-expand {
  display: none;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: var(--button-text-color);
  height: 46px;
  border-radius: 8px;
  cursor: pointer;
}

.text {
  color: var(--text-color);
  font-size: 20px;
  margin-top: 35px;
}

.cancel {
  color: var(--text-color-secondary);
  margin-top: 20px;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 58px;
}

.select__header {
  width: 140px;
  border: 1px solid var(--text-color-hint);
  padding: 10px;
  color: var(--text-color);
  border-radius: 8px;
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('./../public/arrow-bottom.svg') no-repeat right 10px center;
  background-size: 12px;
  background-color: var(--background-color);
}

.select__header::-ms-expand {
  display: none;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader__t {
  color: var(--text-color-hint);
  font-weight: bold;
  font-size: 20px;
}

.done__t {
  color: var(--text-color);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.loader__v {
  color: var(--text-color-hint);
  font-size: 20px;
  display: flex;
}
/*
.done__block {
  display: flex;
  align-items: center;
  justify-content: center;
  height:calc(100vh - 190px);
  flex-direction: column;
  gap:30px;
  position: relative;
}*/


.done__block {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.cardPageDone {
  height: calc(100vh - 190px);
}

.loader__block {
  height:calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.load {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
}

.time {
  font-weight: bold;
}

.info__text {
  font-weight: bold;
  font-size: 15px;
  color: var(--text-color-hint);
  margin: 8px 0;
}

.info__text span {
  color:red;
}

.credit__card {
  width: 100%;
  position: relative;
  padding: 20px 25px;
  border-radius: 8px;
  background: /*linear-gradient(58deg, #28A836, #45FF59)*/ gray
}

.image__theme {
  width: 50px;
  position: absolute;
  right:36px;
  top:90px;
  height: 50px;
  display: block;
  margin-left: auto;
}

.start__load {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1E2324;
}

.credit__card__info {
  margin: 14px 0;
  color: white;
  font-size: 22px;
}

.photo {
  width: 100%;
  background-color: red;
  color:var(--button-text-color);
  height: 46px;
  border-radius: 8px;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  justify-content: center;
}

.photo svg path {
  fill: var(--button-text-color);
}

.photo__data {
  display: flex;
  align-items: center;
  gap:6px;
}

.copy__icon {
  position: absolute;
  top:5px;
  right:5px;
}

.done__text {
  color:var(--text-color);
  font-size: 18px;
  font-weight: 500;
  text-align:  center;
}


.bank__name {
  color:white;
  font-size: 22px;
  font-weight: bold;
}
.help__text {
  position: absolute;
  bottom:0;
  left:0;
  color:var(--text-color);

}

.help__text span {
  color: var(--button-color);
  cursor: pointer;
}

.help__text_ord {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
  color:var(--text-color);
}

.help__text_ord span {
  color: rgb(161, 45, 45);
  cursor: pointer;
}
.red__text {
  color: red;
  margin-top: 10px;
  font-size: 17px;
}

.logo1 {
  fill: var(--text-color);
}

.order__textm {
  font-size: 11px;
}

.modal {
  position: fixed;
  top:0;
  left:0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  bottom: 0;
  width: 100wv;
  height: 100vh;
  backdrop-filter: blur(10px);
}


.modal__block {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  height: 150px;
  border-radius: 15px;
  background-color: red;
}

.modal__text {
  font-size: 18px;
  color:white;
  font-weight: 500;
}

.modal__btn {
  width: 100%;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border:1px solid white;
  border-radius: 15px;
}